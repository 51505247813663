<template>
  <b-list-group class="my-2">
    <h4>Доступні шаблони знижок:</h4>
    <b-skeleton-wrapper :loading="discountTemplatesLoading">
      <template #loading>
        <b-card>
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </template>
      <b-list-group-item
          v-for="template in discountTemplates"
          :key="template.id"
          :to="{ name: 'discount', params: { id: template.id } }"
      >
        {{ template.title }}
      </b-list-group-item>
    </b-skeleton-wrapper>
  </b-list-group>
</template>

<script>
import { BListGroup, BListGroupItem, BSkeletonWrapper, BSkeleton, BCard } from "bootstrap-vue";

import {useDiscountTemplatesFetch} from "@/composition/discounts/useDiscountTemplatesFetch";
export default {
  name: "AvailableDiscounts",

  components: {
    BListGroup,
    BListGroupItem,
    BSkeletonWrapper,
    BSkeleton,
    BCard,
  },

  setup() {
    const {
      fetchDiscountTemplates,
      fetchAllDiscounts,
      discountTemplates,
      discountTemplatesLoading
    } = useDiscountTemplatesFetch();

    fetchDiscountTemplates();
    fetchAllDiscounts();

    return {
      discountTemplates,
      discountTemplatesLoading,
    };

  }
}
</script>

<style scoped>

</style>